import Selectr from 'Selectr';

class SelectBox {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-select',
            searchAttr: 'search',
            singleAttr: 'single',
            taggableAttr: 'taggable'
        };

        this.$select = element;

        this.settings = Object.assign({}, settings, options);

        this.initialize();
    }

    initialize () {
        this.buildCustomSelect();
    }

    buildCustomSelect () {
        let searchable = false;
        const customClass = null;

        if (this.$select.getAttribute(this.settings.initAttr + '-' + this.settings.searchAttr) !== null) {
            searchable = true;
        }

        Object.defineProperty(Selectr.prototype, 'mobileDevice', {
            get () { return false; },
            set () {},
            enumerable: true,
            configurable: true
        });

        this.selectbox = new Selectr(this.$select, {
            allowDeselect: false,
            customClass: customClass,
            defaultSelected: false,
            searchable: searchable,
            clearable: false,
            placeholder: this.$select.getAttribute('placeholder'),
            disabled: this.$select.disabled,
            renderOption: myRenderFunction
        });

        function myRenderFunction (option) {
            const template = [`<span class="selectr-option__inner" data-select-option tabindex="0">${option.textContent.trim()}</span>`];
            return template.join('');
        }

        // const handleOutsideClick = (e) => {
        //     if (!this.selectbox.container.contains(e.target)) {
        //         this.selectbox.close();
        //     }
        // };

        const handleOptionKeyboardControll = ($$selectBoxOptions) => {
            $$selectBoxOptions.forEach($selectBoxOption => {
                $selectBoxOption.addEventListener('keydown', (event) => {
                    if (event.code === 'Space' || event.code === 'Enter') {
                        event.preventDefault();
                        $selectBoxOption.parentElement.click();
                    }
                });
            });
        };

        this.selectbox.on('selectr.init', () => {
            const $selectBoxElement = this.selectbox.container.querySelector('.selectr-selected');
            $selectBoxElement.addEventListener('keydown', (event) => {
                if (event.code === 'Space' || event.code === 'Enter' || event.code === 'Escape') {
                    event.preventDefault();
                }

                if (event.code !== 'Escape') {
                    setTimeout(() => {
                        const $$selectBoxOptions = this.selectbox.container.querySelectorAll('[data-select-option]');
                        handleOptionKeyboardControll($$selectBoxOptions);
                    }, '1000');
                }
            });

            if (searchable === true) {
                this.selectbox.container.classList.add('has--search');
                this.selectbox.input.type = 'text';
                this.selectbox.input.setAttribute('placeholder', this.$select.getAttribute('placeholder'));
            }
        });
    }
}

export { SelectBox };
