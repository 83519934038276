import './app.scss';

import domready from 'domready';
// import libs and components
import { EventScroller } from './js/event-scroller';
import { ResizeHandler } from './js/resize-handler';
import { MediaMatchHandler } from './js/media-match-handler';
import 'Lazysizes';
// import Header from './components/header/header';
import Navigation from './components/navigation/navigation';
import Footer from './components/footer/footer';
import { getOffset, getParent, loadScript, smoothScrollTo } from './js/helper';
import Text from './components/text/text';
import stickybits from 'stickybits';
import { bindTrackingClick } from './js/tracking';
import { SelectBox } from './components/form/select-box';
// import { Search } from './components/search/search';
import Cookies from 'js-cookie';

// GLOBAL OPTIONS
window.OPTIONS = {
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><b class="loader icon-refresh"><i>loading...</i></b></b>'
};

let activeTabnavId = null;

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();
window.youtubeApi = false;
window.mediaMatch = new MediaMatchHandler();

window.scrolling = true;

let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
let scrollDirection = ''; // eslint-disable-line

window.initContent = ($content) => {
    bindTrackingClick($content);

    const $texts = $content.querySelectorAll('[data-text]');
    for (let i = 0; i < $texts.length; i++) {
        const $text = $texts[i];

        const textAPI = new Text($text);
        $text.API = textAPI;
    }

    // Check for all Headlines inside rte class and wrap them with span
    const $$headlines = $content.querySelectorAll('.rte h1:not(:has(span)), .rte h2:not(:has(span)), .rte h3:not(:has(span)), .rte h4:not(:has(span)), .rte h5:not(:has(span))');
    if ($$headlines.length > 0) {
        $$headlines.forEach((headline) => {
            headline.innerHTML = `<span>${headline.textContent}</span>`;
        });
    }

    const $mathjaxs = $content.querySelectorAll('.math-tex');

    if (!window.MathJax && $mathjaxs.length > 0) {
        loadScript('//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML&delayStartupUntil=configured').then(() => {
            window.MathJax.Hub.Config({
                showMathMenu: false
            });
            window.MathJax.Hub.Configured();
        }, () => {
            console.error('fail to load script');
        });
    }

    const $countdowns = $content.querySelectorAll('[data-countdown="root"]');
    if ($countdowns.length > 0) {
        import('./components/countdown/countdown').then(({ Countdown }) => {
            for (let i = 0; i < $countdowns.length; i++) {
                const $countdown = $countdowns[i];

                const countdownAPI = new Countdown($countdown);
                $countdown.API = countdownAPI;
            }
        });
    }

    const $augmentedReality = $content.querySelector('[data-ar="root"]');
    if ($augmentedReality) {
        import('./components/augmented-reality/ar').then(({ Augmented }) => {
            const AR = new Augmented($augmentedReality);
            AR.initialize();
        });
    }

    const $tables = $content.querySelectorAll('.rte table');
    if ($tables.length > 0) {
        import('./components/scrolltable/scrolltable').then(({ Scrolltable }) => {
            for (let i = 0; i < $tables.length; i++) {
                const $table = $tables[i];
                const scrolltableAPI = new Scrolltable($tables[i]);
                $table.API = scrolltableAPI;
            }
        });
    }

    const $lotties = $content.querySelectorAll('[data-lottie]');
    if ($lotties.length > 0) {
        import('./components/lottie/lottie').then(({ Lottie }) => {
            for (let i = 0; i < $lotties.length; i++) {
                const $lottie = $lotties[i];

                const lottieAPI = new Lottie($lottie);
                $lottie.API = lottieAPI;
            }
        });
    }

    const $glossaries = $content.querySelectorAll('[data-glossary]');
    if ($glossaries.length > 0) {
        import('./components/glossary/glossary').then(({ Glossary }) => {
            for (let i = 0; i < $glossaries.length; i++) {
                const $glossary = $glossaries[i];

                const glossaryAPI = new Glossary($glossary, {
                    loader: window.OPTIONS.loader
                });
                $glossary.API = glossaryAPI;
            }
        });
    }

    const $anchors = $content.querySelectorAll('a[href^="#"], a[href*="#"]');
    for (let i = 0; i < $anchors.length; i++) {
        const $anchor = $anchors[i];
        const anchorHref = $anchor.getAttribute('href');
        const anchorParts = anchorHref.split('//');
        const anchorPath = anchorParts[0];
        let internal = false;
        let samePage = false;

        if (anchorHref === anchorPath) {
            internal = true;

            if (anchorPath.indexOf('#') === 0) {
                samePage = true;
            }
        }

        if (internal === samePage === true) {
            const jumpToAnchor = (e) => {
                const id = anchorHref.split('#')[1];
                const $anchorTarget = document.getElementById(id);

                if ($anchorTarget !== null) {
                    let anchorDimensions = getOffset($anchorTarget);
                    let anchorMovement = anchorDimensions.top;

                    if ($anchorTarget.closest('[data-anchor]') && $anchorTarget.closest('[data-anchor]').nextElementSibling) {
                        const $nextTarget = $anchorTarget.closest('[data-anchor]').nextElementSibling;

                        anchorDimensions = getOffset($nextTarget);
                        anchorMovement = anchorDimensions.top;
                        const headerHeight = document.querySelector('[data-header="root"]')?.clientHeight;
                        const tabNavHeight = $anchor.closest('.tabnav') ? $anchor.closest('.tabnav')?.clientHeight : 0;

                        anchorMovement = anchorMovement - (headerHeight + tabNavHeight);
                    }
                    smoothScrollTo(0, anchorMovement, 400, () => {
                        const $nextElement = $anchorTarget.parentElement.nextElementSibling;
                        if ($nextElement) {
                            const $nextTabindex = $nextElement.querySelector('tabindex');
                            const $nextLink = $nextElement.querySelector('a');
                            if ($nextTabindex) {
                                $nextTabindex.focus();
                            } else if ($nextLink) {
                                $nextLink.focus();
                            } else {
                                if ($nextElement.hasAttribute('tabindex')) {
                                    $nextElement.focus();
                                } else {
                                    $nextElement.setAttribute('tabindex', 0);
                                    $nextElement.focus();
                                    $nextElement.removeAttribute('tabindex', 0);
                                }
                            }
                        }
                    });
                } else {
                    smoothScrollTo(0, window.innerHeight, 1000);
                }

                e.preventDefault();
            };

            $anchor.addEventListener('click', (e) => {
                jumpToAnchor(e);
            });

            $anchor.addEventListener('keydown', (event) => {
                if (event.code === 'Space' || event.code === 'Enter') {
                    event.preventDefault();
                    jumpToAnchor(event);
                }
            });
        }
    }

    const $moodvideos = $content.querySelectorAll('[data-moodmedia="root"]');
    for (let i = 0; i < $moodvideos.length; i++) {
        const $moodvideo = $moodvideos[i];
        const $moodvideoMedia = $moodvideo.querySelector('[data-moodmedia="media"]');
        const $moodvideoReplay = $moodvideo.querySelector('[data-moodmedia="replay"]');
        const $moodvideoPlay = $moodvideo.querySelector('[data-moodmedia="play"]');
        const $moodvideoPause = $moodvideo.querySelector('[data-moodmedia="pause"]');

        if ($moodvideoMedia.getAttribute('loop') === null) {
            $moodvideoMedia.addEventListener('ended', () => {
                if ($moodvideoReplay) {
                    $moodvideoReplay.classList.add('is--visible');
                    $moodvideoReplay.classList.remove('is--hidden');
                    $moodvideoReplay.focus();
                    $moodvideoReplay.setAttribute('aria-hidden', 'false');
                }
                $moodvideoPlay.classList.add('is--hidden');
                $moodvideoPause.classList.add('is--hidden');
            });

            $moodvideoMedia.addEventListener('play', () => {
                if ($moodvideoReplay) {
                    $moodvideoReplay.classList.remove('is--visible');
                    $moodvideoReplay.setAttribute('aria-hidden', 'true');
                }
            });

            const replay = () => {
                $moodvideoMedia.pause();
                $moodvideoMedia.currentTime = 0;
                $moodvideoMedia.play();
                $moodvideoReplay.classList.remove('is--visible');
                $moodvideoReplay.classList.add('is--hidden');
                $moodvideoPlay.classList.remove('is--hidden');
                $moodvideoPause.classList.remove('is--hidden');
                $moodvideoReplay.setAttribute('aria-hidden', 'true');
            };

            if ($moodvideoReplay) {
                $moodvideoReplay.addEventListener('click', () => {
                    replay();
                });

                $moodvideoReplay.addEventListener('keydown', (event) => {
                    if (event.code === 'Space' || event.code === 'Enter') {
                        event.preventDefault();
                        replay();
                        $moodvideoReplay.classList.add('is--hidden');
                        $moodvideoPause.focus();
                    }
                });
            }
        }

        const play = () => {
            $moodvideoMedia.play();
            $moodvideoPlay.classList.add('is--hidden');
            $moodvideoPlay.classList.remove('is--visible');
            $moodvideoPause.classList.remove('is--hidden');
            $moodvideoPause.classList.add('is--visible');
            $moodvideoPlay.setAttribute('aria-hidden', 'true');
            $moodvideoPause.setAttribute('aria-hidden', 'false');
        };

        const pause = () => {
            $moodvideoMedia.pause();
            $moodvideoPlay.classList.remove('is--hidden');
            $moodvideoPlay.classList.add('is--visible');
            $moodvideoPause.classList.add('is--hidden');
            $moodvideoPause.classList.remove('is--visible');
            $moodvideoPlay.setAttribute('aria-hidden', 'false');
            $moodvideoPause.setAttribute('aria-hidden', 'true');
        };

        if ($moodvideoPlay && $moodvideoPause) {
            $moodvideoPlay.addEventListener('click', (e) => {
                play();
            });

            $moodvideoPlay.addEventListener('keydown', (event) => {
                if (event.code === 'Space' || event.code === 'Enter') {
                    event.preventDefault();
                    play();
                    $moodvideoPause.focus();
                }
            });

            $moodvideoPause.addEventListener('click', () => {
                pause();
            });

            $moodvideoPause.addEventListener('keydown', (event) => {
                if (event.code === 'Space' || event.code === 'Enter') {
                    event.preventDefault();
                    pause();
                    $moodvideoPlay.focus();
                }
            });
        }
    }

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover]');

            if ($hovers.length > 0) {
                let $currentHover = null;

                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];
                    let hoverClass = $hover.getAttribute('data-hover');

                    if (hoverClass === '') {
                        hoverClass = 'hover';
                    }

                    $hover.addEventListener('mouseover', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                            $currentHover = $hover;
                        }
                    });
                }

                document.addEventListener('touchstart', (e) => {
                    if ($currentHover !== null) {
                        const isClickInside = $currentHover.contains(e.target);
                        let currentHoverClass = $currentHover.getAttribute('data-hover');

                        if (currentHoverClass === '') {
                            currentHoverClass = 'hover';
                        }

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(currentHoverClass)) {
                                $currentHover.classList.remove(currentHoverClass);
                                $currentHover = null;
                            }
                        }
                    }
                });
            }
        }
    }

    const $$imageZooms = $content.querySelectorAll('[data-imagezoom="root"]');
    if ($$imageZooms.length > 0) {
        import('./components/image/image-zoom').then(({ ImageZoom }) => {
            for (let i = 0; i < $$imageZooms.length; i++) {
                const $imageZoom = $$imageZooms[i];
                const imageZoomAPI = new ImageZoom($imageZoom, {
                    loader: window.OPTIONS.loader
                });
                $imageZoom.API = imageZoomAPI;
            }
        });
    }

    // Click handler for phone-links to set variable and prevent "beforeunload" class
    document.querySelectorAll('a[href^="tel:"]').forEach((telLink) => {
        telLink.addEventListener('click', () => {
            window.clickTel = true;
        });
    });

    window.addEventListener('beforeunload', () => {
        if (!window.clickTel) {
            $content.classList.add('page-change');
            window.scrollTo(0, 0);
        }
    });

    const $navigation = $content.querySelector('[data-nav="root"]');
    if ($navigation !== null) {
        const navigationAPI = new Navigation($navigation, {
            eventScroller: window.eventScroller,
            resizeHandler: window.resizeHandler
        });
        $navigation.API = navigationAPI;
    }

    const $footer = $content.querySelector('[data-footer="root"]');
    if ($footer !== null) {
        const footerAPI = new Footer($footer, {
            resizeHandler: window.resizeHandler
        });
        $footer.API = footerAPI;
    }

    if ($content === document.body) {
        const $cookieBanner = document.querySelector('[data-cookiebanner]');
        if ($cookieBanner) {
            import('./components/cookie-banner/cookie-banner').then(({ CookieBanner }) => {
                const cookieBannerAPI = new CookieBanner($cookieBanner);
                $cookieBanner.API = cookieBannerAPI;
            });
        }

        const $breadcrumbs = document.querySelector('[data-breadcrumbs="root"]');
        if ($breadcrumbs) {
            const $breadcrumbsScroller = $breadcrumbs.querySelector('[data-breadcrumbs="scroller"]');
            $breadcrumbsScroller.scrollLeft = 10000;
        }

        const $languageSwitch = document.querySelectorAll('[data-language="root"]');

        for (let index = 0; index < $languageSwitch.length; index++) {
            const languageSwitchAPI = new SelectBox($languageSwitch[index]);
            const languageSwitch = languageSwitchAPI.selectbox;

            languageSwitch.on('selectr.init', () => {
                $languageSwitch.API = languageSwitch;
            });

            $languageSwitch[index].addEventListener('change', () => {
                const uri = $languageSwitch[index].value;
                window.location.href = uri;
            });
        }

        const $pagerSwitch = document.querySelector('[data-pager-href]');
        if ($pagerSwitch) {
            const pagerSwitchAPI = new SelectBox($pagerSwitch);
            const pagerSwitch = pagerSwitchAPI.selectbox;

            pagerSwitch.on('selectr.init', () => {
                $pagerSwitch.API = pagerSwitch;
            });

            $pagerSwitch.addEventListener('change', () => {
                const page = $pagerSwitch.value;
                const uri = $pagerSwitch.getAttribute('data-pager-href');
                const newUri = uri.replace('page=0', 'page=' + page);
                window.location.href = newUri;
            });
        }
    }

    window.addEventListener('scroll', function () {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }
        lastScrollTop = st <= 0 ? 0 : st;
    });

    checkAnimation(window.eventScroller);
    window.checkMoodVideo(window.eventScroller);
    checkBevels(window.eventScroller);
    checkTabnav();
    displayDealerButton();
    displayDefaultButton();
    displayShareButton();
    displayRestartButton();
    displayContactButton();
    contactButtonScroll();
    window.checkModelFinder(window.eventScroller);
    setTimeout(function () {
        checkInview(window.eventScroller);
    }, 500);
    checkIdInput();
    checkAnimateCookie();
    addSafariStyles();

    window.eventScroller.customFunctions.push(() => {
        checkAnimation(window.eventScroller);
        checkInview(window.eventScroller);
        window.checkMoodVideo(window.eventScroller);
        checkBevels(window.eventScroller);
        checkTabnav();
        displayDealerButton();
        displayShareButton();
        displayDefaultButton();
        displayRestartButton();
        displayContactButton();
        window.checkModelFinder(window.eventScroller);
    });
};

const checkInview = (scroller) => {
    const $inviews = document.querySelectorAll('[data-inview]');

    for (let i = 0; i < $inviews.length; i++) {
        const $inview = $inviews[i];
        const onScreenStatus = scroller.onScreen($inview, 20);

        if (onScreenStatus === true && !$inview.classList.contains('inview')) {
            $inview.classList.add('inview');
            if ($inview.classList.contains('animate-next')) {
                $inviews[i + 1].classList.add('inview');
            }
        }
    }

    // const $bgAnimationInviews = document.querySelectorAll('[data-bg-animation]');
    //
    // if ($bgAnimationInviews.length > 0) {
    //     const body = document.querySelector('body');
    //     if (!body.classList.contains('bg-animate')) {
    //         body.classList.add('bg-animate');
    //     }
    //     let activeBgColor = '';
    //
    //     for (let i = 0; i < $bgAnimationInviews.length; i++) {
    //         const $inviewElement = $bgAnimationInviews[i];
    //         if (i === 0 && !$inviewElement.classList.contains('bg-animate-first')) {
    //             $inviewElement.classList.add('bg-animate-first');
    //         }
    //         const elementColor = $inviewElement.getAttribute('data-bg-animation');
    //         let onScreenStatus = scroller.onScreen($inviewElement, 20);
    //
    //         if (scrollDirection === 'up') {
    //             onScreenStatus = scroller.onScreen($inviewElement, window.innerHeight - 20);
    //         }
    //
    //         if ($inviewElement.classList.contains('collage')) {
    //             onScreenStatus = scroller.onScreen($inviewElement, window.innerHeight / 2);
    //         }
    //
    //         if (onScreenStatus) {
    //             const $nextElement = $inviewElement.nextElementSibling;
    //
    //             if ($nextElement) {
    //                 if (!$nextElement.classList.contains('bg-animate')) {
    //                     $inviewElement.classList.add('has--bevel-padding');
    //                 }
    //             }
    //
    //             const newBgColor = 'bg-' + elementColor;
    //             activeBgColor = newBgColor;
    //         }
    //     }
    //
    //     if (activeBgColor) {
    //         if (!body.classList.contains(activeBgColor)) {
    //             body.classList.remove('bg-development', 'bg-competitive', 'bg-cyclist', 'bg-white', 'bg-red', 'bg-black');
    //             body.classList.add(activeBgColor);
    //         }
    //     }
    // }
};

const checkAnimation = (scroller) => {
    const $animations = document.querySelectorAll('[data-lottie]');

    for (let i = 0; i < $animations.length; i++) {
        const $animation = $animations[i];
        const onScreenStatus = scroller.onScreen($animation, window.innerHeight / 4);

        if ($animation.closest('[data-tabs="content"]')) {
            if (onScreenStatus === true && $animation.closest('[data-tabs="content"]').classList.contains('is--active')) {
                $animation.API.lottie.play();
            }
        }

        if (!$animation.classList.contains('animated') && $animation.API && onScreenStatus === true) {
            $animation.API.lottie.play();
            $animation.classList.add('animated');
        }
    }
};

window.checkMoodVideo = (scroller) => {
    const $videos = document.querySelectorAll('[data-moodvideo]');

    for (let i = 0; i < $videos.length; i++) {
        const $video = $videos[i];
        const onScreenStatus = scroller.onScreen($video);
        let autoplay = true;

        if ($video.getAttribute('data-mobileonly') !== null) {
            if (typeof window.OPTIONS.breakpoints.l !== 'undefined' && window.innerWidth >= window.OPTIONS.breakpoints.l) {
                autoplay = false;
            }
        }

        const $hotspot = getParent($video, '.hotspot');
        if ($hotspot !== null) {
            autoplay = false;

            if ($hotspot.classList.contains('is--visible')) {
                autoplay = true;
            }
        }

        if (autoplay === true) {
            if (onScreenStatus === true) {
                if ($video.getAttribute('playing') === null) {
                    $video.play();
                }
                $video.setAttribute('playing', true);
            } else if ($video.paused !== true) {
                $video.pause();
                $video.removeAttribute('playing');
            } else {
                $video.removeAttribute('playing');
            }
        }
    }
};

const checkBevels = (scroller) => {
    const $bevels = document.querySelectorAll('[data-bevel="root"]');

    for (let i = 0; i < $bevels.length; i++) {
        const $bevel = $bevels[i];
        const scrollInfo = scroller.getScrollInfo();
        const dir = scrollInfo.direction;
        const onScreenStatus = scroller.onScreen($bevel, 100, true);
        const $previousElement = $bevel.previousElementSibling;

        if (onScreenStatus === true) {
            if (!$bevel.classList.contains('is--visible')) {
                $bevel.classList.add('is--visible');

                if ($previousElement !== null) {
                    $previousElement.classList.add('is--hiding');
                }
            }
        } else {
            if (dir === 'up') {
                if (!$bevel.classList.contains('is--hiding') && $bevel.classList.contains('is--visible')) {
                    $bevel.classList.remove('is--visible');

                    if ($previousElement !== null && $previousElement.classList.contains('is--hiding')) {
                        $previousElement.classList.remove('is--hiding');
                    }
                }
            }
        }
    }
};

const setActiveTabnav = ($tabnavs, id) => {
    for (let i = 0; i < $tabnavs.length; i++) {
        const $currentTabnav = $tabnavs[i];
        if (!$currentTabnav.classList.contains('has--no-anchor-highlighting')) {
            const $currentTabnavScroller = $currentTabnav.querySelector('[data-tabnav="items"]');
            const $allItems = $currentTabnav.querySelectorAll('[data-tabnav="item"]');
            const $activeItem = $currentTabnav.querySelector('a[href="#' + id + '"]');

            for (let a = 0; a < $allItems.length; a++) {
                const $currentItem = $allItems[a];

                if ($currentItem === $activeItem) {
                    $currentItem.classList.add('is--active');
                    $currentTabnavScroller.scrollLeft = $currentItem.offsetLeft - $allItems[0].offsetLeft;
                } else {
                    $currentItem.classList.remove('is--active');
                }
            }
        }
    }
};

const checkTabnav = () => {
    const $tabnavs = document.querySelectorAll('[data-tabnav="root"]');
    const $tabnavAnchors = document.querySelectorAll('[data-tabnav="anchor"]');

    if ($tabnavs.length > 0) {
        for (let i = 0; i < $tabnavs.length; i++) {
            const $tabnav = $tabnavs[i];
            const elementDimensions = $tabnav.getBoundingClientRect();
            const elementTop = elementDimensions.top;

            if (elementTop > 10) {
                if ($tabnav.classList.contains('is--sticky')) {
                    $tabnav.classList.remove('is--sticky');
                }
            } else {
                $tabnav.classList.add('is--sticky');
            }
        }

        if ($tabnavAnchors.length > 0) {
            let currentActiveId = null;
            for (let i = 0; i < $tabnavAnchors.length; i++) {
                const $anchor = $tabnavAnchors[i];
                const elementDimensions = $anchor.getBoundingClientRect();
                const elementTop = elementDimensions.top;

                if (elementTop - window.innerHeight / 2 <= 0) {
                    currentActiveId = $anchor.getAttribute('id');
                }
            }

            if (activeTabnavId !== currentActiveId) {
                activeTabnavId = currentActiveId;
                setActiveTabnav($tabnavs, activeTabnavId);
            }
        }
    }
};

const displayDealerButton = () => {
    const hideContainer = document.querySelector('.product-details');
    const ajaxContainer = document.querySelector('.ajax-content');
    const $dealerContainer = document.querySelector('[data-dealer="container"]');
    const scrollPositionTop = window.pageYOffset;

    if ($dealerContainer) {
        if (hideContainer && ajaxContainer === null) {
            const calculateContainerPosition = hideContainer.getBoundingClientRect().top;
            if (calculateContainerPosition < scrollPositionTop) {
                $dealerContainer.classList.add('is--visible');
                $dealerContainer.classList.remove('is--hidden');
                hideButtonText($dealerContainer);
                hideDealerButton();
            } else {
                $dealerContainer.classList.remove('is--visible');
                $dealerContainer.classList.add('is--hidden');
                hideDealerButton();
            }
        } else if (ajaxContainer !== null) {
            $dealerContainer.classList.add('is--visible');
            $dealerContainer.classList.remove('is--hidden');
            hideDealerButton();
        } else {
            $dealerContainer.classList.add('is--visible');
            $dealerContainer.classList.remove('is--hidden');
            hideButtonText($dealerContainer);
            hideDealerButton();
        }
    }
};

const hideDealerButton = () => {
    const footerContainer = document.querySelector('[data-footer="root"]');
    const footerContainerPosition = footerContainer.getBoundingClientRect();
    const dealerContainer = document.querySelector('[data-dealer="container"]');

    if (dealerContainer) {
        if (footerContainerPosition.top < window.innerHeight && footerContainerPosition.bottom >= 0) {
            dealerContainer.classList.remove('is--visible');
            dealerContainer.classList.add('is--hidden');
        }
    }
};

const displayShareButton = () => {
    const hideContainer = document.querySelector('.product-details');
    const ajaxContainer = document.querySelector('.ajax-content');
    const $shareContainer = document.querySelector('[data-share="container"]');
    const scrollPositionTop = window.pageYOffset;

    if ($shareContainer) {
        if (hideContainer && ajaxContainer === null) {
            const calculateContainerPosition = hideContainer.getBoundingClientRect().top;

            if ($shareContainer.classList.contains('is--fair')) {
                hideButtonText($shareContainer);
            }

            if (calculateContainerPosition < scrollPositionTop) {
                $shareContainer.classList.add('is--visible');
                $shareContainer.classList.remove('is--hidden');
                hideButtonText($shareContainer);
                hideShareButton();
            } else {
                $shareContainer.classList.remove('is--visible');
                $shareContainer.classList.add('is--hidden');
                hideShareButton();
            }
        } else if (ajaxContainer !== null) {
            $shareContainer.classList.add('is--visible');
            $shareContainer.classList.remove('is--hidden');
            hideShareButton();
        } else {
            $shareContainer.classList.add('is--visible');
            $shareContainer.classList.remove('is--hidden');
            hideButtonText($shareContainer);
            hideShareButton();
        }
    }
};

const displayDefaultButton = () => {
    const hideContainer = document.querySelector('.product-details');
    const ajaxContainer = document.querySelector('.ajax-content');
    const $buttonContainers = document.querySelectorAll('[data-button="container"]');
    const scrollPositionTop = window.pageYOffset;

    if ($buttonContainers.length > 0) {
        $buttonContainers.forEach(($buttonContainer) => {
            if (hideContainer && ajaxContainer === null) {
                const calculateContainerPosition = hideContainer.getBoundingClientRect().top;
                if (calculateContainerPosition < scrollPositionTop) {
                    $buttonContainer.classList.add('is--visible');
                    $buttonContainer.classList.remove('is--hidden');
                    hideButtonText($buttonContainer);
                    hideDefaultButton($buttonContainer);
                } else {
                    $buttonContainer.classList.remove('is--visible');
                    $buttonContainer.classList.add('is--hidden');
                    hideDefaultButton($buttonContainer);
                }
            } else if (ajaxContainer !== null) {
                $buttonContainer.classList.add('is--visible');
                $buttonContainer.classList.remove('is--hidden');
                hideDefaultButton($buttonContainer);
            } else {
                $buttonContainer.classList.add('is--visible');
                $buttonContainer.classList.remove('is--hidden');
                hideButtonText($buttonContainer);
                hideDefaultButton($buttonContainer);
            }
        });
    }
};

const displayRestartButton = () => {
    const $shareContainer = document.querySelector('[data-restart="container"]');
    const $container = document.querySelector('[data-wheel-finder="root"]');
    const scrollPositionTop = window.pageYOffset;

    if ($shareContainer && $container) {
        const calculateContainerPosition = $container.getBoundingClientRect().top;
        if (calculateContainerPosition < scrollPositionTop) {
            $shareContainer.classList.add('is--visible');
            $shareContainer.classList.remove('is--hidden');
            hideButtonText($shareContainer);
            hideButton($container, $shareContainer);
        } else {
            $shareContainer.classList.remove('is--visible');
            $shareContainer.classList.add('is--hidden');
            hideButton($container, $shareContainer);
        }
    }
};

const hideShareButton = () => {
    const footerContainer = document.querySelector('[data-footer="root"]');
    const footerContainerPosition = footerContainer.getBoundingClientRect();
    const $shareContainer = document.querySelector('[data-share="container"]');

    if ($shareContainer) {
        if (footerContainerPosition.top < window.innerHeight && footerContainerPosition.bottom >= 0) {
            $shareContainer.classList.remove('is--visible');
            $shareContainer.classList.add('is--hidden');
        }
    }
};

const hideButton = (container, buttonContainer) => {
    if (container && buttonContainer) {
        const containerPosition = container.getBoundingClientRect();

        const $shareContainer = document.querySelector('[data-share="container"]');

        if ($shareContainer) {
            if (containerPosition.top < window.innerHeight && containerPosition.bottom >= 0) {
                $shareContainer.classList.remove('is--visible');
                $shareContainer.classList.add('is--hidden');
            }
        }
    }
};

const hideDefaultButton = ($buttonContainer) => {
    let footerContainer = document.querySelector('[data-footer="root"]');
    // const $buttonContainer = document.querySelector('[data-button="container"]');
    const contactModule = document.querySelector('.contact-module');

    if (contactModule) {
        footerContainer = contactModule;
    }
    const footerContainerPosition = footerContainer.getBoundingClientRect();

    if ($buttonContainer) {
        if (footerContainerPosition.top < window.innerHeight && footerContainerPosition.bottom >= 0) {
            $buttonContainer.classList.remove('is--visible');
            $buttonContainer.classList.add('is--hidden');
        }
    }
};

const hideButtonText = ($container) => {
    const buttonContainers = $container.querySelectorAll('[data-button="container"]');

    buttonContainers.forEach((buttonContainer) => {
        const button = buttonContainer.querySelector('[data-button="text"]');

        if (button) {
            setTimeout(() => {
                button.classList.remove('is--active');
                buttonContainer.classList.remove('is--active');
                button.classList.add('not--active');
                buttonContainer.classList.add('not--active');
            }, 500);
        }
    });
};

const displayContactButton = () => {
    let hideContainer = document.querySelector('.product-search');
    const productSupportResult = document.querySelector('.product-support-result');
    if (productSupportResult) {
        hideContainer = productSupportResult;
    }
    const $contactContainer = document.querySelector('[data-contact="container"]');
    const scrollPositionTop = window.pageYOffset;

    if ($contactContainer) {
        if (hideContainer) {
            const hideContainerMarginTop = parseInt(window.getComputedStyle(hideContainer).getPropertyValue('margin-top').replace('px', ''));
            const calculateContainerPosition = hideContainer.getBoundingClientRect().top - hideContainerMarginTop;
            if (calculateContainerPosition < scrollPositionTop) {
                $contactContainer.classList.add('is--visible');
                $contactContainer.classList.remove('is--hidden');
                hideButtonText($contactContainer);
                hideContactButton();
            } else {
                $contactContainer.classList.remove('is--visible');
                $contactContainer.classList.add('is--hidden');
                hideContactButton();
            }
        } else {
            $contactContainer.classList.add('is--visible');
            $contactContainer.classList.remove('is--hidden');
            hideButtonText($contactContainer);
            hideContactButton();
        }
    }
};

const hideContactButton = () => {
    let footerContainer = document.querySelector('[data-footer="root"]');
    const contactModule = document.querySelector('.contact-module');
    if (contactModule) {
        footerContainer = contactModule;
    }
    const footerContainerPosition = footerContainer.getBoundingClientRect();
    const contactContainer = document.querySelector('[data-contact="container"]');

    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

    if (contactContainer) {
        if (footerContainerPosition.top < window.innerHeight && footerContainerPosition.bottom >= 0) {
            if (isIOS && isSafari) {
                contactContainer.classList.add('has--no-animation');
            }
            contactContainer.classList.remove('is--visible');
            contactContainer.classList.add('is--hidden');
        }
    }
};

const contactButtonScroll = () => {
    const $button = document.querySelector('[data-contact-button="container"]');
    if ($button) {
        $button.addEventListener('click', (e) => {
            const $contactModule = document.querySelector('.contact-module');
            smoothScrollTo(0, $contactModule.offsetTop, 500);
            e.preventDefault();
        });
    }
};

// add safari styles for forms with upload
const addSafariStyles = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setTimeout(() => {
        const elementsToHide = document.querySelectorAll('.qq-alert-dialog-selector, .qq-confirm-dialog-selector, .qq-prompt-dialog-selector');
        if (isSafari) {
            if (elementsToHide.length > 0) {
                for (let i = 0; i < elementsToHide.length; i++) {
                    elementsToHide[i].style.display = 'none';
                }
            }
        }
    }, 500);
};

// check if model finder is in view to show fixed overlay with products
window.checkModelFinder = () => {
    const $modelFinders = document.querySelectorAll('[data-model-finder="root"]');

    for (let i = 0; i < $modelFinders.length; i++) {
        const $modelFinder = $modelFinders[i];
        // model finder positions
        const modelFinderOffset = getOffset($modelFinder);
        const modelFinderOffsetTop = modelFinderOffset.top;
        const modelFinderOffsetBottom = modelFinderOffset.bottom;
        // scroll positions
        const scrollPositionTop = window.pageYOffset;
        const scrollPositionBottom = scrollPositionTop + window.innerHeight;

        // different viewport has different condition
        let conditionInView = '';
        if (window.innerWidth < window.OPTIONS.breakpoints.l) {
            conditionInView = (scrollPositionTop >= modelFinderOffsetTop - 50 && scrollPositionBottom <= modelFinderOffsetBottom + 230) ||
                (window.innerHeight >= $modelFinder.getBoundingClientRect().height && scrollPositionTop < modelFinderOffsetTop && scrollPositionBottom > modelFinderOffsetBottom);
        } else {
            conditionInView = (scrollPositionTop >= modelFinderOffsetTop - 150 && scrollPositionBottom <= modelFinderOffsetBottom + 150) ||
                (window.innerHeight >= $modelFinder.getBoundingClientRect().height && scrollPositionTop < modelFinderOffsetTop && scrollPositionBottom > modelFinderOffsetBottom);
        }

        if (conditionInView) {
            $modelFinder.classList.add('model-finder--in-view');
        } else {
            $modelFinder.classList.remove('model-finder--in-view');
        }

        // add/remove model-finder--fixed-arrows class depending on wether arrows are visible or not
        // currently only used for desktop design
        const $arrow = $modelFinder.querySelector('.model-finder__arrows');
        const arrowOffset = getOffset($arrow);
        const arrowTop = arrowOffset.top;
        const isSiteheaderVisible = document.querySelector('.siteheader.is--visible');
        const siteheaderDistance = (isSiteheaderVisible !== null) ? (56 + 65) : 56;

        if (scrollPositionTop > arrowTop - siteheaderDistance) {
            $modelFinder.classList.add('model-finder--fixed-arrows');
        } else {
            $modelFinder.classList.remove('model-finder--fixed-arrows');
        }

        // add/remove model-finder--result-in-view class for close button in mobile view
        const $result = $modelFinder.querySelector('[data-model-finder="result"]');
        const resultOffset = getOffset($result);
        const resultOffsetTop = resultOffset.top;
        const resultOffsetBottom = resultOffset.bottom;
        if (scrollPositionTop > resultOffsetTop && scrollPositionTop < resultOffsetBottom - 100) {
            $modelFinder.classList.add('model-finder--result-in-view');
        } else {
            $modelFinder.classList.remove('model-finder--result-in-view');
        }
    }
};

// function to control the input of the id input -> only numbers are allowed
const checkIdInput = () => {
    const $inputs = document.querySelectorAll('[data-idform]');

    if ($inputs.length > 0) {
        for (let i = 0; i < $inputs.length; i++) {
            const $input = $inputs[i];

            // Handle keypress of input
            $input.addEventListener('keypress', (e) => {
                const theEvent = e || window.event;
                let key;

                key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);

                const regex = /[0-9]|\./;
                if (!regex.test(key)) {
                    theEvent.returnValue = false;
                    if (theEvent.preventDefault) theEvent.preventDefault();
                }
            });

            // Handle paste event of input
            $input.addEventListener('paste', (e) => {
                const theEvent = e || window.event;
                const key = event.clipboardData.getData('text/plain');

                const result = key.match(/^\d+$/);
                if (!result) {
                    theEvent.returnValue = false;
                    if (theEvent.preventDefault) theEvent.preventDefault();
                }
            });
        }
    }
};

const checkAnimateCookie = () => {
    if (Cookies.get('animate') !== 'visible') {
        Cookies.set('animate', 'visible');
        const body = document.querySelector('body');
        body.classList.add('animate');
        body.classList.remove('page-change');
    }
};

domready(function () {
    const initApplication = () => {
        window.initContent(document.querySelector('body'), window.OPTIONS);

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });

        window.eventScroller.init();
        window.resizeHandler.init();

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);
    };

    if (window.Modernizr.ie11) {
        stickybits('[data-sticky]');
        initApplication();
    } else {
        initApplication();
    }
});
